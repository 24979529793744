<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="10">
        <eb-date-range v-model="dateRange" @change="changeDate"></eb-date-range>
      </el-col>

      <el-col :span="4">
        <el-button type="primary" @click="getList"  v-auth="this.per.EXPORT_EXCHANGE_AMMETER"
          >查询</el-button
        >
      </el-col>

      <el-col :span="4">
        <el-button type="primary" @click="doExportStationExchange"  v-auth="this.per.EXPORT_EXCHANGE_AMMETER"
          >导出换电耗电数据</el-button
        >
      </el-col>
    </el-row>

    <el-table
      height="650"
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column label="站点" prop="stationName"></el-table-column>
      <el-table-column label="站点编号" prop="stationMacId"></el-table-column>
      <el-table-column
        label="换电次数"
        prop="changeNums"
        align="right"
        sortable
      ></el-table-column>
      <!-- <el-table-column
        label="平均换电次"
        prop="sendaverage"
        align="right"
        sortable
      ></el-table-column> -->
      <el-table-column label="耗电量" prop="electrtotal" align="right" sortable>
        <template #default="scope">
          <span v-if="scope.row.sequenceScoNums">{{
            scope.row.sequenceScoNums
          }}</span>
          <span v-else>{{ 0 }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column
        label="次平均耗电量"
        prop="electraverage"
        align="right"
        sortable
      >
        <template #default="scope">
          <span v-if="scope.row.electrtotal">{{
            ( scope.row.electrtotal /scope.row.sendtotal).toFixed(2)
          }}</span>
          <span v-else>{{ 0 }}</span>
        </template>
      </el-table-column> -->
      <!-- <el-table-column
        label="日平均耗电量"
        prop="electraverage"
        align="right"
        sortable
      >
      </el-table-column> -->
    </el-table>
  </div>
</template>

<script>
import StationBarCharts from "./components/station-bar.vue";
import {
  exchangeRecordDay,
  exportStationExchange,
} from "@/api/statistics.js";
import moment from "moment";

export default {
  components: { StationBarCharts },
  data() {
    let startTime = moment()
      .startOf("day")
      .subtract(1, "month")
      .format("YYYY-MM-DD HH:mm:ss");
    let endTime = moment().endOf("day").format("YYYY-MM-DD HH:mm:ss");

    return {
      dateRange: [startTime, endTime],
      dataSource: [],
      stationCount: 0,
      stationQuery: {
        stationStartTime: startTime,
        stationEndTime: endTime,
      },
    };
  },

  computed: {
    stationStartTimeRange() {
      return [
        this.stationQuery.stationStartTime,
        this.stationQuery.stationEndTime,
      ];
    },
  },

  methods: {
    doExportStationExchange() {
      exportStationExchange({
        startTime: this.stationQuery.stationStartTime,
        endTime: this.stationQuery.stationEndTime,
      }).then((res) => {
        this.util.apiDownLoadFile(
          res.data,
          "application/vnd.ms-excel",
          `${this.stationQuery.stationStartTime}-${this.stationQuery.stationEndTime}站点换电耗电数据.xlsx`
        );
      });
    },

    changeDate(e) {
      this.stationQuery.stationStartTime = moment(e[0])
        .startOf("d")
        .format("YYYY-MM-DD HH:mm:ss");
      this.stationQuery.stationEndTime = moment(e[1])
        .endOf("d")
        .format("YYYY-MM-DD HH:mm:ss");
      this.getStationData();
    },

    getStationData() {
      exchangeRecordDay({
        startTime: this.stationQuery.stationStartTime,
        endTime: this.stationQuery.stationEndTime,
        pageIndex: 1,
        pageSize: 200
      }).then((res) => {
        let list = res.data.data.records;
        this.dataSource = list;
        let data = {
          label: [],
          value: [],
        };
        this.stationCount = 0;
        list.map((item) => {
          data.label.push(item.stationName);
          data.value.push(item.iTotal);
          this.stationCount += item.iTotal;
        });
        // this.$refs.stationBarCharts.updateCharts(data);
      });
    },
  },

  mounted() {
    this.getStationData();
  },
};
</script>